var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Share")])]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Share ScotsWork Data with Other Employees")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.active = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"mt-2"},[_vm._v("You can share this list with other employees who may have a part in helping to oversee your student workers. This will give them access to the timeclock data, their work status, and the joint class schedule.")]),_c('v-row',[_c('v-col',{attrs:{"cols":4}},[_c('v-card',{staticStyle:{"height":"300px"},attrs:{"elevation":"2"}},[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.placements),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('v-list-item',{key:_id},[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":8}},[_c('v-card',{staticStyle:{"height":"300px"},attrs:{"elevation":"2"}},[_c('v-toolbar',[_c('directory-search',{attrs:{"person-filter":['Faculty', 'Staff'],"label":"Employee Search","value-field":"pidm","outlined":"","hide-details":""},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}}),_c('v-btn',{attrs:{"disabled":_vm.userSearch == null || _vm.userSearch === '',"color":"success"},on:{"click":_vm.addUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-plus-circle")]),_vm._v(" Add User ")],1)],1),_c('v-list',_vm._l((_vm.selectedPlacement),function(ref){
var pidm = ref.pidm;
var name = ref.name;
return _c('v-list-item',{key:pidm},[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeUser(pidm)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-trash")])],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }